$gap: 20px;

.settingsGroupContent {
    &__btnIcon {
        margin-right: 10px;
    }

    &__buttons {
        padding-top: 10px;
    }

    &__btn:not(:last-child) {
        margin-right: 16px;
    }

    &__inputsContainer {
        margin-top: $gap;
    }

    &__checkBoxInput {
        margin-bottom: $gap;
    }
}