// global color. DO NOT USE DIRECTLY IN SCHEMA

$ct-blue: #1066a8;
$ct-orange: #f07f0e;
$ct-white: #ffffff;

$ct-gray-50: #f8f8f8;
$ct-gray-100: #ececec;
$ct-gray-150: #f6f6f6;
$ct-gray-175: #f2f2f2;
$ct-gray-200: #eeeeee;
$ct-gray-250: #e2e2e2;
$ct-gray-300: #e0e0e0;
$ct-gray-450: #cecece;
$ct-gray-500: #bdbdbd;
$ct-gray-600: #a2a2a2;
$ct-gray-650: #a3a3a3;
$ct-gray-700: #828282;
$ct-gray-850: #4f4f4f;
$ct-editor-text: #181a1b;

$ct-black-50: #333333;

$ct-green-1: #27ae60;
$ct-green-2: #00e676;
$ct-green-3: #219653;
$ct-green-4: #1a9f29;
$ct-green-5: #00bb8e;
$ct-green-6: #deefe5;
$ct-green-7: #a3cb38;
$ct-green-8: #2f9a41;
$ct-green-9: #4bb34b;

$ct-purple-1: #7b519d;

$ct-blue-1: #2ca5e0;
$ct-blue-2: #0099e3;
$ct-blue-3: #0eabdc;
$ct-blue-4: #1066a8;
$ct-blue-5: #0b426d;

// generates color tones for theme based on main color var
$ct-primary-1: color-mix(in oklab, var(--primary) 10%, #ffffff 100%);
$ct-primary-2: color-mix(in hsl, var(--primary) 100%, #f4ffe5 10%);
$ct-primary-3: color-mix(in lab, var(--primary) 10%, #ffffff 100%);
$ct-primary-4: color-mix(in srgb, var(--primary) 60%, #ffffff 100%);

// generates color tones for scenarios based on main color var
$ct-editor-1: color-mix(in srgb, var(--editor) 20%, #ffffff 100%);
$ct-editor-2: color-mix(in srgb, var(--editor) 40%, #ffffff 90%);
$ct-editor-3: color-mix(in srgb, var(--editor) 30%, #ffffff 100%);
$ct-editor-4: color-mix(in lch, var(--editor) 20%, #ffffff 100%);
$ct-editor-5: color-mix(in srgb, var(--editor) 15%, #ffffff 100%);

$ct-red-1: #ca0c13;
$ct-red-2: #e34646;
$ct-red-3: #e0001b;
$ct-red-4: #d22323;
$ct-red-5: #fbdbe1;
$ct-red-6: #fbe9e9;

$ct-orange-1: #d94e02;
$ct-orange-2: #eb8e39;
$ct-orange-3: #f79f1f;

$ct-yellow-1: #ffc312;
$ct-yellow-2: #f3ee64;
